export enum Compatibility {
    FULL,
    NONE,

    COMPATIBLE_WITH_SOME, // eg. C and C E
    SOME_ARE_COMPATIBLE, // eg. C E and C

    // Worst case (combination of COMPATIBLE_WITH_SOME and SOME_ARE_COMPATIBLE)
    LIMITED_OVERLAP, // eg. C A E and C I
}
