import data from '@/data/elec/sockets.json';
import socketMetadata from '@/data/elec/socket-info.json';
import { CountryCode } from '@/types/CountryCode';
import { Compatibility } from '@/types/Compatibility';
import { DataRecord } from '@/types/DataRecord';
import { SocketName } from '@/types/elec/SocketName';
import { SocketType } from '@/types/elec/SocketType';
import { SocketInfo } from '@/types/elec/SocketInfo';

class FaultySocketError extends Error {
  constructor(message: string, public readonly country: CountryCode) {
    super(`${message} for country '${country}'`);
    this.name = 'FaultySocketError';
  }
}

export interface SocketCompatibilityReport {
    plugCompatibilityWithSocket: Compatibility;
    unusablePlugs: SocketType[];
    unusableSockets: SocketType[];
    usablePlugs: SocketType[];
    usableSockets: SocketType[];

    plugs: SocketType[];
    sockets: SocketType[];

    frequencyCompatibility: Compatibility;
    voltageCompatibility: Compatibility;

    plug: SocketInfo;
    socket: SocketInfo;
}

// Country's electrical information
interface RawSocketInfo extends DataRecord {
    plugs: SocketName[];
    hz: string[];
    v: string[];
}

const sockets = data as {
    [code in CountryCode]?: RawSocketInfo;
};

export const SocketTypes = socketMetadata as {
    [socket in SocketName]: {
        grounded: boolean;
        compatiblePlugs: SocketName[];
        colloquial: string;
    };
};

export class Socket {
    private socketInfo: Promise<SocketInfo>;

    constructor(public readonly country: CountryCode) {
      this.socketInfo = new Promise<RawSocketInfo | undefined>(
        (resolve) => resolve(sockets[country]),
      )

        .then((socket) => {
          if (!socket) {
            throw new FaultySocketError('No electrical data listed', country);
          }

          return socket;
        })

        .then((socket: RawSocketInfo) => {
          if (socket.plugs.length < 1) {
            throw new FaultySocketError('No plugs listed', country);
          }
          if (socket.hz.length < 1) {
            throw new FaultySocketError('No frequency listed', country);
          }
          if (socket.v.length < 1) {
            throw new FaultySocketError('No voltage listed', country);
          }

          return Socket.parse(socket);
        });
    }

    private static parse(socket: RawSocketInfo): SocketInfo {
      const toNum = (v: string): number => {
        const n = parseInt(v, 10);
        if (Number.isNaN(n)) {
          throw new Error('Problem parsing socket');
        }

        return n;
      };

      return {
        ...socket,
        hz: socket.hz.map(toNum),
        v: socket.v.map(toNum),
        plugs: this.makeSocketObjects(...socket.plugs),
      };
    }

    /**
     * Get this Socket's info. Memoized, so will not re-fetch data.
     */
    public get info() {
      return this.socketInfo;
    }

    static willFitInto(plug: SocketName, socket: SocketName): boolean {
      const { compatiblePlugs } = SocketTypes[socket];
      return compatiblePlugs.includes(plug);
    }

    /**
     * Voltage compatiblitiy
     */
    private static regularDeviceWontBreak(vPlug: number, vSocket: number): boolean {
      const safe = {
        min: vSocket * 0.9,
        max: vSocket * 1.1,
      };
      return safe.min <= vPlug && vPlug <= safe.max;
    }

    private static getCompatibility<T>(
      our: T[],
      their: T[],
      fn: (our: T, their: T) => boolean = (ourOne, theirOne) => ourOne === theirOne,
    ): {
        oursUsable: T[];
        theirsUsable: T[];
        oursUnusable: T[];
        theirsUnusable: T[];
        compatibility: Compatibility;
    } {
      // Sockets none of ours will fit into
      const unusableSockets = their.filter((socket) => typeof our.find((plug) => fn(plug, socket)) === 'undefined');

      // Plugs we can't fit anywhere
      const unusablePlugs = our.filter((plug) => typeof their.find((socket) => fn(plug, socket)) === 'undefined');

      const plugsWeCanFitSomewhere = our.filter((plug) => !unusablePlugs.includes(plug));

      let plugCompatibilityWithSocket: Compatibility;

      if (unusableSockets.length === 0 && unusablePlugs.length === 0) {
        plugCompatibilityWithSocket = Compatibility.FULL;
      } else if (unusablePlugs.length === 0) {
        plugCompatibilityWithSocket = Compatibility.COMPATIBLE_WITH_SOME;
      } else if (unusableSockets.length === 0) {
        plugCompatibilityWithSocket = Compatibility.SOME_ARE_COMPATIBLE;
      } else if (plugsWeCanFitSomewhere.length > 0) {
        plugCompatibilityWithSocket = Compatibility.LIMITED_OVERLAP;
        // TODO: Check this logic
      } else {
        plugCompatibilityWithSocket = Compatibility.NONE;
      }

      return {
        oursUnusable: unusablePlugs,
        oursUsable: plugsWeCanFitSomewhere,
        theirsUnusable: unusableSockets,
        theirsUsable: their.filter((socket) => !unusableSockets.includes(socket)),
        compatibility: plugCompatibilityWithSocket,
      };
    }

    async compatibleWith(country: Socket): Promise<SocketCompatibilityReport> {
      const their = await country.info;
      const our = await this.info;

      const {
        oursUnusable,
        theirsUnusable,
        oursUsable,
        theirsUsable,
        compatibility: plugCompatibilityWithSocket,
      } = Socket.getCompatibility(
        our.plugs.map(({ name }) => name),
        their.plugs.map(({ name }) => name),
        Socket.willFitInto,
      );

      // We have a subset? Warn user to only take that SocketType plugs
      // They have a subset? Warn user to avoid those SocketType outlets
      // Both are subsets? -> Warn user to only take those SocketType plugs, and warn to avoid the others.

      // TODO: We should check whether it's possible to take one type, but find a wrong socket (even if right ones exist)

      return {
        plugCompatibilityWithSocket,
        unusablePlugs: Socket.makeSocketObjects(...oursUnusable),
        unusableSockets: Socket.makeSocketObjects(...theirsUnusable),
        usablePlugs: Socket.makeSocketObjects(...oursUsable),
        usableSockets: Socket.makeSocketObjects(...theirsUsable),
        plugs: our.plugs,
        sockets: their.plugs,

        frequencyCompatibility: Socket.getCompatibility(our.hz, their.hz).compatibility,
        voltageCompatibility: Socket.getCompatibility(
          our.v,
          their.v,
          Socket.regularDeviceWontBreak,
        ).compatibility,

        plug: our,
        socket: their,
      };
    }

    private static makeSocketObjects(...socketNames: SocketName[]): SocketType[] {
      return socketNames.map((name) => ({
        name,
        colloquial: SocketTypes[name].colloquial,
        grounded: SocketTypes[name].grounded,
      }));
    }

    public static async exists(country: CountryCode): Promise<boolean> {
      try {
        await new Socket(country).info;
        return true;
      } catch (e) {
        if (e instanceof FaultySocketError) {
          return false;
        }

        throw e;
      }
    }

    public static areGroundingPairs({ colloquial, grounded }: SocketType, b: SocketType): boolean {
      return colloquial === b.colloquial && grounded !== b.grounded;
    }
}
